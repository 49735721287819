<section class="flex flex-col gap-10">
  <div class="flex flex-row w-full items-start justify-center gap-5">
    <a (click)="goBack()" class="flex gap-2.5 items-center mt-[6px]">
      <mat-icon svgIcon="arrow-left-light" class="w-7 h-7"></mat-icon>
    </a>
    <span class="inline-flex items-center">
      <h2>Conditions Générales d'Utilisation pour les Formateurs</h2>
    </span>
  </div>
  <div class="flex flex-col gap-5">
    <h4>Préambule</h4>
    <p>La société <b>Fyyyre</b> met à disposition du Formateur l’accès à une plateforme d'apprentissage en ligne,
      <b>Fyyyre</b>
      (ci-après «
      la Plateforme »), afin qu’il puisse offrir à ses apprenants des parcours de formation personnalisés et
      interactifs,
      grâce à des serious games.
    </p>
    <p>Les présentes Conditions Générales d'Utilisation (ci-après les « CGU ») définissent les droits et obligations des
      parties dans le cadre de l’utilisation de la Plateforme. Elles s'appliquent aux relations entre <b>Fyyyre</b>,
      éditrice
      de la
      Plateforme, et les utilisateurs professionnels de cette dernière (le « Formateur » ou les « Formateurs »),
      agissant dans
      le cadre de leurs activités professionnelles pour gérer le parcours de formation et mettre la Plateforme à
      disposition
      de leurs apprenants (ci-après les « Apprenants »).
    </p>
    <p>Les CGU sont un élément contractuel qui intervient en complément de contrats conclus individuellement avec chaque
      Formateur ; en cas de contradiction entre ces autres contrats et les CGU, les règles de priorité stipulées le
      Contrat de
      service principal s’appliquent.
    </p>
    <p>Les CGU entrent en vigueur à compter de leur acceptation par le Formateur lors de la signature du Contrat de
      service
      principal et sont susceptibles d'être modifiées à tout moment. La date de dernière modification des CGU est
      indiquée
      ci-après : <b>13 novembre 2024</b>.
    </p>
  </div>
  <div class="flex flex-col gap-5">
    <h4>Article 1er. Objet</h4>

    <p>Les présentes Conditions Générales d'Utilisation ont pour objet de définir les conditions dans lesquelles Fyyyre
      fournit
      au Formateur un accès à la Plateforme.</p>

    <p>La Plateforme propose un ensemble de fonctionnalités permettant aux Formateurs de :</p>
    <ul class="ml-4">
      <li>
        <p class="break-words">● <b>Proposer, par l’intermédiaire de Fyyyre, des serious games à leurs Apprenants ;</b>
        </p>
      </li>
      <li>
        <p class="break-words">● <b>Gérer les utilisateurs ;</b></p>
      </li>
      <li>
        <p class="break-words">● <b>Suivre les résultats</b>des apprenants ;</p>
      </li>
      <li>
        <p class="break-words">● <b>Adapter et personnaliser les contenus.</b></p>
      </li>
    </ul>

  </div>
  <div class="flex flex-col gap-5">
    <h4>Article 2. Accès et utilisation du service</h4>

    <div>
      <h6>2.1 Conditions d’accès à la Plateforme</h6>

      <p>L'accès à la gestion de la Plateforme est réservé aux Formateurs disposant d'un compte fourni par <b>Fyyyre</b>
        (ci-après «
        Fyyyre »), ou créé par le compte d’administration attribué au Formateur. Chaque Formateur se voit attribuer un
        compte
        d’administration et peut, à partir de ce compte, créer et gérer des comptes de gestionnaires et de référents
        pour ses
        collaborateurs, ainsi que des comptes d’apprenants.</p>

      <p>
        La création des comptes de gestionnaires, de référents et d’apprenants est limitée à un nombre défini dans le
        Contrat.
        Le dépassement de ce nombre de comptes entraînera l’application de l’Article 4 des présentes CGU.
      </p>
    </div>
    <div>
      <h6>2.2 Limites d’utilisation</h6>

      <p>Le Formateur s’engage à respecter les limites d'utilisation définies ci-après, et à les faire respecter par ses
        collaborateurs et ses apprenants :</p>
      <ul class="ml-4">
        <li>
          <p class="break-words">● <b>Interdiction des usages détournés.</b> Toute utilisation de la Plateforme qui
            sortirait de l’objet du Contrat de service principal est strictement interdite.</p>
        </li>
        <li>
          <p class="break-words">● <b>Respect de la réglementation relative à la protection des données
              personnelles.</b> Le Formateur s’engage à ne pas réaliser, via l’utilisation de la Plateforme, de
            traitements illicites ou abusifs de
            données personnelles, au sens du RGPD. Les détails des obligations des parties concernant les données à
            caractère
            personnel sont détaillées dans l’Engagement relatif aux Données Personnelles (EDP).</p>
        </li>
        <li>
          <p class="break-words">● <b>Légalité des opérations.</b> L’utilisation de la Plateforme par le Formateur ne
            doit en aucun cas enfreindre la loi applicable, entendue en son sens
            large. Cela comprend, sans s’y limiter : les dispositions relatives à la propriété intellectuelle, à la
            protection des
            données personnelles, aux droits des tiers et au piratage informatique.</p>
        </li>
        <li>
          <p class="break-words">● <b>Interdiction de tests techniques.</b> Pour des raisons de sécurité, le Formateur
            et ses utilisateurs s’engagent à ne pas effectuer de tests techniques
            (notamment des tests de performance, de qualité ou de sécurité) sur la Plateforme, sauf autorisation
            spécifique de
            Fyyyre donnée au préalable et par écrit. Toute tentative non autorisée de test pourrait compromettre
            l’intégrité et la
            disponibilité de la Plateforme et entraînera des mesures de suspension ou de résiliation du compte lié à ces
            tests.</p>
        </li>
      </ul>
    </div>
  </div>
  <div class="flex flex-col gap-5">
    <h4>Article 3. Création de comptes d’utilisateurs finaux</h4>
    <div>
      <h6>3.1 Processus d’enregistrement et gestion des utilisateurs</h6>

      <p>Le Formateur se charge lui-même de la création et de la gestion des différents comptes sur la Plateforme. À
        cette fin,
        le Formateur dispose de deux options :</p>

      <ul class="ml-4">
        <li>
          <p>● <b>Ajout manuel des utilisateurs.</b> Le Formateur peut ajouter individuellement chaque utilisateur en
            fournissant les informations nécessaires à
            l’enregistrement de chaque compte.</p>
        </li>
        <li>
        <li>
          <p>● <b>Import en masse des utilisateurs.</b> Le Formateur peut, à sa convenance, importer en une seule fois
            plusieurs utilisateurs via un fichier compatible avec la
            Plateforme.</p>
        </li>
      </ul>
    </div>
    <div>
      <h6>3.2 Gestion des mots de passe et sécurité des comptes utilisateurs</h6>

      <p>L’utilisation sécurisée des mots de passe par les administrateurs, gestionnaires et référents relève de la
        responsabilité exclusive du Formateur. Le Formateur doit s'assurer que chacun de ces utilisateurs crée et
        maintient un
        mot de passe long et fort pour accéder à la Plateforme. Fyyyre recommande l’utilisation de mots de passe
        complexes,
        comprenant des caractères alphanumériques et des symboles, et rappelle que la réutilisation et le stockage en
        clair des
        mots de passe sont des mauvaises pratiques de sécurité, qui doivent être découragées auprès des utilisateurs..
      </p>
      <p>
        Le Formateur est responsable de toute activité effectuée via les comptes de ses utilisateurs et s'engage à
        sensibiliser
        ces derniers aux bonnes pratiques de sécurité, notamment en matière de confidentialité des identifiants et de
        protection
        contre l’accès non autorisé. En cas de compromission d’un compte utilisateur, le Formateur est tenu de prendre
        immédiatement les mesures nécessaires pour sécuriser le compte et, le cas échéant, d’informer Fyyyre dans les
        plus brefs
        délais.
      </p>
    </div>
  </div>
  <div class="flex flex-col gap-5">
    <h4>Article 4. Actions entraînant des frais supplémentaires</h4>
    <div>
      <h6>4.1 Limites aux modifications des serious games</h6>
      <p>Le Formateur dispose d’une certaine latitude pour adapter les serious games mis à disposition sur la Plateforme
        aux
        besoins de ses apprenants, dans les limites fixées ci-dessous. Il est autorisé à :</p>
      <ul class="ml-4">
        <li>
          <p>● <b>Ajouter, remplacer ou supprimer des questions</b> sous réserve que les modifications ne dépassent pas
            <b>10% du nombre total de questions initiales</b> du serious game.
          </p>
        </li>
        <li>
        <li>
          <p>● <b>Modifier l’histoire</b> ou le contenu narratif, dans la limite de <b>10% du nombre total de
              caractères</b> constituant le texte d’origine.</p>
        </li>
      </ul>
      <p>Ces ajustements permettent au Formateur de personnaliser partiellement le contenu sans altérer le serious game
        de
        manière substantielle. En cas de modifications dépassant ces seuils, le Formateur accepte que des frais
        supplémentaires
        lui soient facturés. Ces frais seront calculés proportionnellement au pourcentage total de modifications
        supplémentaires
        appliquées, sur la base de coût initial de création du serious game.</p>
    </div>
    <div>
      <h6>4.2 Limitation du nombre d’utilisateurs et des rôles possibles</h6>
      <p>La Plateforme est conçue pour accueillir un nombre limité d’utilisateurs par Formateur, conformément aux termes
        du
        Contrat. Au-delà de ce nombre, le Formateur peut étendre l’accès à la Plateforme jusqu’au terme du Contrat,
        moyennant
        des frais additionnels par tranches de 10 utilisateurs supplémentaires, calculées de la façon suivante :
        <b>(coût total /
          nombre initial d’utilisateurs) x 10</b>.
      </p>
      <p>Toute <b>augmentation significative</b> du nombre d’utilisateurs (plus de 50), ou la création de rôles
        spécifiques nécessitant
        une configuration technique particulière fera l’objet d’un devis complémentaire.</p>
      <p>Voici un exemple indicatif de l’augmentation des utilisateurs, pour un contrat initial couvrant jusqu’à 100
        apprenants
        pour un prix total de 100 000 € :</p>

      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="utilisateur">
          <th mat-header-cell *matHeaderCellDef> Utilisateurs </th>
          <td mat-cell *matCellDef="let element"> {{element.utilisateur}} </td>
        </ng-container>

        <ng-container matColumnDef="utilisateurs90">
          <th mat-header-cell *matHeaderCellDef> 90 utilisateurs actifs </th>
          <td mat-cell *matCellDef="let element"> {{element.utilisateurs90}} </td>
        </ng-container>

        <ng-container matColumnDef="utilisateurs120">
          <th mat-header-cell *matHeaderCellDef> 120 utilisateurs actifs </th>
          <td mat-cell *matCellDef="let element"> {{element.utilisateurs120}} </td>
        </ng-container>

        <ng-container matColumnDef="utilisateurs160">
          <th mat-header-cell *matHeaderCellDef> 160 utilisateurs actifs </th>
          <td mat-cell *matCellDef="let element"> {{element.utilisateurs160}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
  <div class="flex flex-col gap-5">
    <h4>Article 5. Propriété intellectuelle</h4>
    <div>
      <h6>5.1 Droits de Fyyyre sur la Plateforme
      </h6>

      <p>La Plateforme ainsi que
        l’ensemble de ses éléments constitutifs, incluant, sans s’y limiter, les textes, graphismes, interfaces,
        logos, codes sources, et serious games (ci-après « le Contenu »), sont la propriété exclusive de <b>Fyyyre
        </b>(ci-après « Fyyyre ») et sont protégés par les lois et règlements relatifs à la propriété intellectuelle.
        L’Apprenant s'engage à respecter les droits de Fyyyre et à ne procéder à aucun acte susceptible de porter
        atteinte à ces droits, notamment :</p>

      <ul class="ml-4">
        <li>
          <p>● <b>Aucune revente, redistribution ou
              transfert</b> de la Plateforme ou de son Contenu n’est autorisé.</p>

        </li>
        <li>
          <p>● <b>Interdiction de
              toute création de version dérivée (“fork”)</b> de la Plateforme. L’Apprenant s’engage à ne pas
            modifier ou tenter de modifier de manière substantielle le code, l’interface ou les fonctionnalités de
            la Plateforme sans l’autorisation écrite de Fyyyre.</p>
        </li>
      </ul>
    </div>
    <div>
      <h6>5.2 Droits et obligations concernant les contenus créés par le Formateur et/ou ses utilisateurs</h6>

      <p>Le Formateur et ses utilisateurs peuvent être amenés à créer ou intégrer des contenus spécifiques dans le cadre
        de l’utilisation de la Plateforme, tels que des textes, des questions, des modules de formation ou autres
        éléments
        de jeu (ci-après « les Contenus Formateur »). Le Formateur conserve la pleine propriété intellectuelle de ces
        Contenus
        Formateur, à condition que ceux-ci aient été intégralement créés par lui ou ses Utilisateurs.</p>

      <p>
        En publiant des Contenus Formateur sur la Plateforme, le Formateur accorde à Fyyyre une <b>licence non
          exclusive,
          gratuite,
          et valable pour la durée de son utilisation de la Plateforme</b> permettant à Fyyyre d’héberger, afficher,
        reproduire et, le
        cas échéant, adapter les Contenus Formateur, dans le strict cadre de l’exécution des services et pour assurer le
        bon
        fonctionnement de la Plateforme.
      </p>

      <p>Le Formateur garantit également que les Contenus Formateur ne portent atteinte ni aux droits d’un tiers ni aux
        lois en
        vigueur, conformément à l’Article 2 des CGU.</p>
    </div>
  </div>
  <div>
    <h4>Article 6. Responsabilité</h4>
    <div>
      <h6>6.1 Limites de la responsabilité de Fyyyre</h6>
      <p>Fyyyre s'engage à déployer tous les moyens nécessaires et proportionnés pour assurer la disponibilité et le bon
        fonctionnement de la Plateforme, dans le cadre des obligations découlant du Contrat de service principal.
        Toutefois,
        Fyyyre ne saurait être tenue pour responsable en cas de :</p>
      <ul class="ml-4">
        <li>
          <p>● <b>Perte de données.</b> Le Formateur est invité à prendre les dispositions nécessaires pour demander à
            se faire communiquer régulièrement des
            sauvegardes de ses données par Fyyyre. Fyyyre ne pourra être tenue responsable des pertes de données
            survenant dans le
            cadre de l’utilisation de la Plateforme.</p>
        </li>
        <li>
        <li>
          <p>● <b>Dommages indirects.</b> Fyyyre décline toute responsabilité en cas de dommages indirects, accessoires
            ou consécutifs, y compris, sans s’y
            limiter, les pertes de bénéfices, pertes d’exploitation, pertes de chiffre d’affaires, ou préjudices
            financiers divers
            découlant de l’utilisation de la Plateforme.</p>
        </li>
        <li>
          <p>● <b>Dommages causés par le Formateur, les utilisateurs ou des tiers.</b> Fyyyre ne saurait être tenue
            responsable des dommages résultant d’une mauvaise utilisation de la Plateforme par le
            Formateur, ses collaborateurs ou ses apprenants, ni même des actions de tiers ayant accédé ou s’étant
            maintenus dans la
            Plateforme par des moyens non autorisés, ou ayant détourné les usages de celle-ci.</p>
        </li>
      </ul>
    </div>
    <div>
      <h6>6.2 Exonération de responsabilité en cas de force majeure</h6>
      <p>Fyyyre ne pourra être tenue responsable de tout manquement à ses obligations contractuelles en cas de force
        majeure,
        telle que définie par l’article 1218 du Code Civil, à savoir :</p>
      <ul class="ml-4">
        <li>
          <p>● tout événement échappant au contrôle de Fyyyre,</p>
        </li>
        <li>
          <p>● qui ne pouvait être raisonnablement prévu lors de la conclusion du contrat,</p>
        </li>
        <li>
          <p>● dont les effets ne peuvent être évités par des mesures appropriées,</p>
        </li>
        <li>
          <p>● et rendant impossible l’exécution de ses obligations.</p>
        </li>
      </ul>
      <p>Cela inclut notamment, mais sans s’y limiter : les catastrophes naturelles, incendies, grèves, actes de guerre,
        ou
        pannes des réseaux de télécommunication.</p>
      <p>En cas de survenance d’un tel événement, Fyyyre fera ses meilleurs efforts pour informer le Formateur dans les
        plus
        brefs délais et rétablir l’accès à la Plateforme dès que possible.</p>
    </div>
    <div>
      <h6>6.3 Responsabilité du Formateur en cas de mauvais usage ou de non-respect des CGU</h6>
      <p>Le Formateur est responsable de l’utilisation de la Plateforme par lui-même, ses collaborateurs et ses
        apprenants. Il
        s’engage à respecter et à faire respecter les CGU. En cas de non-respect, le Formateur reconnaît que Fyyyre
        pourra
        engager sa responsabilité pour tous dommages résultant de ces manquements.</p>
    </div>
  </div>
  <div>
    <h4>Article 7. Droit applicable et litiges</h4>
    <p>Les CGU sont soumis au droit et aux juridictions désignées par le Contrat de service principal conclu entre
      Fyyyre et le
      Formateur.</p>
  </div>
  <div>
    <h4>Article 8. Modification des CGU</h4>
    <p>Fyyyre se réserve le droit de modifier à tout moment les CGU. Toute modification substantielle (ajout ou
      suppression
      d’un article ou d’une section, ou modification d’une obligation) fera l'objet d'une notification préalable
      adressée au
      Formateur, par tout moyen approprié, au moins 30 jours ouvrés avant l’entrée en vigueur des modifications.</p>
    <p>À défaut d'opposition écrite du Formateur avant la date d’entrée en vigueur des nouvelles CGU, ce dernier sera
      réputé
      avoir accepté tacitement les modifications.</p>
  </div>
</section>
