<section class="flex flex-col gap-10">
  <div class="flex flex-row w-full items-start justify-center gap-5">
    <a (click)="goBack()" class="flex gap-2.5 items-center mt-[6px] cursor-pointer">
      <mat-icon svgIcon="arrow-left-light" class="w-7 h-7"></mat-icon>
    </a>
    <span class="inline-flex items-center">
      <h2>Conditions Générales d'Utilisation pour les Apprenants</h2>
    </span>
  </div>
  <div class="flex flex-col gap-5">
    <h4>Préambule</h4>
    <p>Bienvenue sur
      <b>Fyyyre</b>, une plateforme de gamification de formation (ci-après « la Plateforme »), éditée par la société
      Fyyyre. Cette plateforme vous est mise à disposition par le formateur vous ayant inscrit à un serious game
      (ci-après
      « le Formateur »), afin de faciliter votre apprentissage, en tant qu’utilisateur inscrit à une de ses formations
      (ci-après « Vous » ou « l’Apprenant »).
    </p>

    <p>Les présentes CGU entrent en
      vigueur à compter de leur acceptation par l’Apprenant lors de son premier accès à la Plateforme et sont
      susceptibles
      d'être modifiées à tout moment. La date de dernière modification des CGU est indiquée ci-après : <b>13 novembre
        2024</b>.</p>
  </div>
  <div class="flex flex-col gap-5">
    <h4>Article 1er. Objet</h4>

    <p>Les présentes Conditions
      Générales d'Utilisation (ci-après les « CGU ») définissent les droits et obligations des Apprenants dans le
      cadre de l’utilisation de la Plateforme. Les Apprenants reconnaissent avoir pris connaissance des CGU et
      s’engagent
      à les respecter en accédant à la Plateforme.</p>

    <p>En utilisant Fyyyre,
      l’objectif est de rendre le processus de formation plus engageant et interactif en intégrant des éléments de
      gamification.</p>

  </div>
  <div class="flex flex-col gap-5">
    <h4>Article 2. Accès et
      utilisation du service</h4>

    <div>
      <h6>2.1 Conditions d’accès à la Plateforme</h6>

      <p>L’accès à la Plateforme ne
        peut se faire qu’après la création d’un compte par un Formateur. Chaque Apprenant s’engage à n’utiliser son
        accès que pour ses propres besoins, sans les mettre à disposition d’autres personnes (y compris d’autres
        Apprenants).</p>
    </div>
    <div>
      <h6>2.2 Gestion des mots de passe et
        sécurité</h6>

      <p>Chaque Apprenant s’engage à créer
        et maintenir un mot de passe long et fort pour accéder à la Plateforme via son compte individuel, et à le garder
        secret. Il est recommandé d’utiliser des mots de passe complexes, comprenant des caractères alphanumériques et
        des
        symboles, et il est rappelé que la</p>
      <p>réutilisation et
        le stockage en clair des mots de passe sont des mauvaises pratiques de sécurité, qui sont découragées.</p>

      <p>Si Vous pensez que votre accès a
        pu être compromis, Vous vous engagez à prévenir Fyyyre dans les meilleurs délais afin d’éviter ou de minimiser
        tout
        incident de sécurité qui pourrait en découler.</p>
    </div>
    <div>
      <h6>2.3 Limites d’utilisation</h6>

      <p>L’Apprenant s’engage à respecter les limites
        d' utilisation définies ci-après :</p>
      <ul class="ml-4">
        <li>
          <p class="break-words">● <b>Interdiction des usages
              détournés.</b> Toute utilisation de la Plateforme qui sortirait de l’objectif de formation déterminé
            par le Formateur est proscrite.</p>

        </li>
        <li>
          <p class="break-words">● <b>Légalité des opérations.</b> L’utilisation de la Plateforme par l’Apprenant ne
            doit en
            aucun cas
            enfreindre
            la loi
            applicable, entendue en son sens large. Cela comprend, sans s’y limiter : les dispositions relatives à la
            propriété intellectuelle, à la protection des données personnelles, aux droits des tiers et au piratage
            informatique.
          </p>

        </li>
        <li>
          <p>● <b>Interdiction de tests
              techniques.</b> Pour des raisons de sécurité, l’Apprenant s’engage à ne pas effectuer de tests
            techniques (notamment des tests de performance, de qualité ou de sécurité) sur la Plateforme, sauf
            autorisation spécifique du Formateur et de Fyyyre donnée au préalable et par écrit. Toute tentative non
            autorisée de test pourrait compromettre l’intégrité et la disponibilité de la Plateforme et entraînera des
            mesures de suspension ou de résiliation du compte lié à ces tests.</p>
        </li>
      </ul>
    </div>

  </div>
  <div class="flex flex-col gap-5">
    <h4>Article 3. Propriété intellectuelle</h4>
    <div>
      <h6>3.1 Droits de Fyyyre sur la Plateforme
      </h6>

      <p>La Plateforme ainsi que
        l’ensemble de ses éléments constitutifs, incluant, sans s’y limiter, les textes, graphismes, interfaces,
        logos, codes sources, et serious games (ci-après « le Contenu »), sont la propriété exclusive de <b>Fyyyre
        </b>(ci-après « Fyyyre ») et sont protégés par les lois et règlements relatifs à la propriété intellectuelle.
        L’Apprenant s'engage à respecter les droits de Fyyyre et à ne procéder à aucun acte susceptible de porter
        atteinte à ces droits, notamment :</p>

      <ul class="ml-4">
        <li>
          <p>● <b>Aucune revente, redistribution ou
              transfert</b> de la Plateforme ou de son Contenu n’est autorisé.</p>

        </li>
        <li>
          <p>● <b>Interdiction de
              toute création de version dérivée (“fork”)</b> de la Plateforme. L’Apprenant s’engage à ne pas
            modifier ou tenter de modifier de manière substantielle le code, l’interface ou les fonctionnalités de
            la Plateforme sans l’autorisation écrite de Fyyyre.</p>
        </li>
      </ul>
    </div>
    <div>
      <h6>3.2 Droits du
        Formateur sur le contenu</h6>

      <p>Le contenu pédagogique ainsi que
        les textes, questions et modules de formation sont la propriété exclusive du Formateur et sont protégés par les
        lois
        et règlements relatifs à la propriété intellectuelle. L’Apprenant s'engage à respecter les droits du Formateur
        et à ne procéder à aucun acte susceptible de leur porter atteinte (en particulier la copie ou la publication de
        ces
        contenus).</p>
    </div>

  </div>
  <div class="flex flex-col gap-5">
    <h4>Article 4. Responsabilité de l’Apprenant</h4>
    <p>L’Apprenant est
      responsable de l’utilisation qu’il fait de la Plateforme. Le non-respect des CGU pourra entraîner la suspension ou
      la résiliation de l’accès de l’Apprenant.</p>

    <p>En cas de dommages causés par
      l’Apprenant lors de son utilisation de la Plateforme, sa responsabilité pourra également être engagée à ce titre.
    </p>

  </div>
  <div class="flex flex-col gap-5">
    <h4>Article 5. Modification des CGU</h4>

    <p>Fyyyre se réserve le droit de
      modifier à tout moment les CGU. Ces modifications seront notifiées à l’Apprenant par écrit, par tout moyen
      raisonnable à disposition du Formateur.</p>
  </div>
</section>
