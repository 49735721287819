import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-input-drag-n-drop',
  templateUrl: './input-drag-n-drop.component.html',
  styleUrl: './input-drag-n-drop.component.scss'
})
export class InputDragNDropComponent extends FieldType {

  files: any[] = [];

  constructor(private sanitizer: DomSanitizer) {
    super();
  }

  ngOnInit() {
    // Set the default value for the form control
    if ((this.field.formControl?.value?.length ?? 0) > 0) {
      this.field.formControl?.value.forEach((img: any) => {
        this.files.push({ url: img })
      })
    }
  }

  onFileSelected(event: Event) {
    const files: any = (event.target as HTMLInputElement).files;
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(files[i]));
        files[i]['url'] = url;
        this.files.push(files[i]);
      }
    }
    this.field.formControl?.setValue(this.files); // Mettez à jour le tableau d'images dans le FormControl
  }

  /**
   * On file drop handler
   */
  onFileDropped($event: any) {
    if (this.field.props && this.field.props['multiple']) {
      this.prepareFilesList($event);
    } else {
      this.prepareFilesList($event.length > 1 ? [$event[0]] : $event);
    }
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      this.files.push(item);
    }
    this.field.formControl?.setValue(files)
  }

  getFormControl(): FormControl {
    return this.field.formControl as FormControl;
  }

  removeFile(index: number) {
    const saveUrl = { ...this.files[index] }['url']
    if (this.field.formControl) {
      this.files[index] = { state: "deleted", url: saveUrl },
        this.field.formControl.value[index] = { state: "deleted", url: saveUrl }
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.files, event.previousIndex, event.currentIndex);
    this.field.formControl?.setValue(this.files)
  }


  getFileExtentionType(file: any) {
    if (file.type.includes("csv") || file.type.includes("xlsx")) {
      return "excel";
    } else if (file.type.includes("pdf")) {
      return "pdf";
    } else {
      return "img";
    }
  }

  showAcceptedExtensions() {
    return this.props['accept']
      .map((ext: string) => " " + ext.toUpperCase()) // Convertir chaque élément du tableau en majuscule
  }
  getAcceptedExtensions() {
    let extAllow: Array<any> = []
    if (this.props['accept']) {
      this.props['accept'].forEach((ext: string) => {
        switch (ext) {
          // Img
          case 'png':
          case 'jpg':
          case 'jpeg':
          case 'svg':
          case 'webp':
            extAllow.push('image/' + ext)
            break;

          // Text
          case 'txt':
            extAllow.push('text/plain')
            break;

          // PDF
          case 'pdf':
            extAllow.push('.pdf')
            break;

          // CSV
          case 'csv':
            extAllow.push('.csv')
            break;

          // xlsx ( Excel Files 2007+  )
          case 'xlsx':
            extAllow.push('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
            break;

          default:
            break;
        }
      });
    }
    return extAllow.join(', ')
  }
}
