import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  AbstractControl,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { GameCardComponent } from '@shared/components/game-card/game-card.component';
import { InfoCardComponent } from '@shared/components/info-card/info-card.component';
import { MaterialModule } from './material/material.module';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import {
  BaseChartDirective,
  provideCharts,
  withDefaultRegisterables,
} from 'ng2-charts';

// SVG Icon - Module
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NavService } from './components/menu-list-item/nav.service';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MenuListItemComponent } from '@shared/components/menu-list-item/menu-list-item.component';
import { SeriousGameCardComponent } from './components/serious-game-card/serious-game-card.component';
import { StandaloneFilterMenuComponent } from './components/standalone-filter-menu/standalone-filter-menu.component';
import { SnackbarService } from './services/snackbar.service';

// Formly - Module
import { ObserverChildDirective } from '@app/shared/directives/appObserverChild.directive';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { ColorPickerModule } from 'ngx-color-picker';
import { CustomTableComponent } from './components/custom-table/custom-table.component';
import { TableDeleteDialogComponent } from './components/custom-table/dialog/delete-dialog/delete-dialog.component';
import { SgQuestionsManagementComponent } from './components/sg-questions-management/sg-questions-management.component';

import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { LoadingDialogComponent } from '@app/shared/components/loading-dialog/loading-dialog.component';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { FrenchDateProviderService } from './adapter/french-date-provider.service';
import { AdminButtonComponent } from './components/admin-button/admin-button.component';
import { CreateOrEditDialogComponent } from './components/create-or-edit-dialog/create-or-edit-dialog.component';
import { GameAndInstanceStatsComponent } from './components/game-and-instance-stats/game-and-instance-stats.component';
import { AutocompleteChipTypeComponent } from './formly/inputs/autocomplete-chip-type/autocomplete-chip-type.component';
import { AutocompleteTypeComponent } from './formly/inputs/autocomplete-type/autocomplete-type.component';
import { DividerFieldType } from './formly/inputs/divider-input';
import { FormlyFieldFile } from './formly/inputs/file.type';
import { FyyyreFillBlankComponent } from './formly/inputs/fyyyre-fill-blank/fyyyre-fill-blank.component';
import { FyyyreMatchingComponent } from './formly/inputs/fyyyre-matching/fyyyre-matching.component';
import { FyyyreQcmComponent } from './formly/inputs/fyyyre-qcm/fyyyre-qcm.component';
import { FyyyreSelectComponent } from './formly/inputs/fyyyre-select/fyyyre-select.component';
import { FyyyreSortingComponent } from './formly/inputs/fyyyre-sorting/fyyyre-sorting.component';
import { FyyyreTextComponent } from './formly/inputs/fyyyre-text/fyyyre-text.component';
import { InputChipComponent } from './formly/inputs/input-chip/input-chip.component';
import { InputColorComponent } from './formly/inputs/input-color/input-color.component';
import { InputDragNDropComponent } from './formly/inputs/input-drag-n-drop/input-drag-n-drop.component';
import { InputFileComponent } from './formly/inputs/input-file/input-file.component';
import { InputImgComponent } from './formly/inputs/input-img/input-img.component';
import { InputTextComponent } from './formly/inputs/input-text/input-text.component';
import { RepeatTypeComponent } from './formly/inputs/repeat.type';
import { RepeatComponent } from './formly/inputs/repeat/repeat.component';
import { TitleComponent } from './formly/inputs/title/title.component';
import { addonsExtension } from './formly/wrappers/addons.extension';
import { FormlyWrapperAddons } from './formly/wrappers/icons.wrapper';
import { PanelWrapperComponent } from './formly/wrappers/panel.wrapper';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ConfirmDialogComponent } from './dialog/confirm-dialog/confirm-dialog.component';
import { CookiesComponent } from './pages/legal/cookies/cookies.component';
import { TermsAndConditionsComponent } from './pages/legal/terms-and-conditions/terms-and-conditions.component';
import { CguTrainersComponent } from './pages/legal/cgu-trainers/cgu-trainers.component';
import { CguLearnersComponent } from './pages/legal/cgu-learners/cgu-learners.component';

// NGX Formly - Customs Validators
// CP - Validator
export function CpValidatorMessage(error: any, field: FormlyFieldConfig) {
  return `"${field.formControl?.value}" n'est pas un code postale valide`;
}
export function CpValidator(control: AbstractControl): ValidationErrors | null {
  return /^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/.test(control.value)
    ? null
    : { date: true };
}

// EMAIL - Validator
export function EmailValidatorMessage(error: any, field: FormlyFieldConfig) {
  return `"${field.formControl?.value}" n'est pas une adresse mail valide`;
}

// PASSWORD - Validator
// At least 8 characters in length / 1 Lowercase letters / 1 Uppercase letters / 1 Numbers / 1 Special characters
export function PasswordValidator(
  control: AbstractControl
): ValidationErrors | null {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#?$%^&*])[a-zA-Z0-9!@#?$%^&*].{8,}/.test(
    control.value
  )
    ? null
    : { password: true };
}
export function PasswordValidatorMessage(error: any, field: FormlyFieldConfig) {
  return `Votre mot de passe doit contenir au moins 8 caractères, une lettre minuscule, une lettre majuscule, un chiffre et un caractère spécial`;
}

// PASSWORD CONFIRMATION
export function PasswordConfirmationValidator(control: AbstractControl) {
  const { password, passwordConfirm } = control.value;

  // avoid displaying the message error when values are empty
  if (!passwordConfirm || !password) {
    return null;
  }

  if (passwordConfirm === password) {
    return null;
  }

  return { passwordConfirmation: true };
}
export function PasswordConfirmationValidatorMessage(
  error: any,
  field: FormlyFieldConfig
) {
  return `Les mots de passe doivent être identique`;
}

// DATE - Validator
export function DateValidatorMessage(error: any, field: FormlyFieldConfig) {
  return `"${field.formControl?.value}" n'est pas une date valide`;
}
export function DateValidator(control: AbstractControl): ValidationErrors {
  return /^(3[01]|[12][0-9]|0?[1-9])(\/|-)(1[0-2]|0?[1-9])\2([0-9]{2})?[0-9]{2}$/.test(
    control.value
  )
    ? { date: null }
    : { date: true };
}

@NgModule({
  declarations: [
    PageNotFoundComponent,
    MenuListItemComponent,
    SeriousGameCardComponent,
    StandaloneFilterMenuComponent,
    FormlyFieldFile,
    InputFileComponent,
    AutocompleteTypeComponent,
    GameCardComponent,
    FyyyreQcmComponent,
    FyyyreFillBlankComponent,
    FyyyreTextComponent,
    FyyyreSelectComponent,
    FyyyreSortingComponent,
    FyyyreMatchingComponent,
    InfoCardComponent,
    SgQuestionsManagementComponent,
    ObserverChildDirective,
    CustomTableComponent,
    TableDeleteDialogComponent,
    InputImgComponent,
    InputColorComponent,
    RepeatTypeComponent,
    PanelWrapperComponent,
    InputTextComponent,
    FormlyWrapperAddons,
    InputDragNDropComponent,
    AutocompleteTypeComponent,
    AutocompleteChipTypeComponent,
    DividerFieldType,
    CreateOrEditDialogComponent,
    LoadingDialogComponent,
    RepeatComponent,
    AdminButtonComponent,
    SafeHtmlPipe,
    InputChipComponent,
    GameAndInstanceStatsComponent,
    TitleComponent,
    ConfirmDialogComponent,
    CookiesComponent,
    TermsAndConditionsComponent,
    CguTrainersComponent,
    CguLearnersComponent,
  ],
  imports: [
    MaterialModule,
    CommonModule,
    ReactiveFormsModule,
    AngularSvgIconModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    FormlyModule.forRoot({
      types: [
        // { name: 'file', component: FormlyFieldFile },
        { name: 'file2', component: InputFileComponent },
        { name: 'dragNdrop', component: InputDragNDropComponent },
        { name: 'input-img', component: InputImgComponent },
        { name: 'input-color', component: InputColorComponent },
        { name: 'input-chip', component: InputChipComponent },
        { name: 'autocomplete', component: AutocompleteTypeComponent },
        { name: 'autocomplete-chip', component: AutocompleteChipTypeComponent },
        { name: 'fyyyre-qcm', component: FyyyreQcmComponent },
        { name: 'fyyyre-fill-blank', component: FyyyreFillBlankComponent },
        { name: 'fyyyre-text', component: FyyyreTextComponent },
        { name: 'fyyyre-select', component: FyyyreSelectComponent },
        { name: 'fyyyre-sorting', component: FyyyreSortingComponent },
        { name: 'fyyyre-matching', component: FyyyreMatchingComponent },
        { name: 'repeat', component: RepeatComponent },
        { name: 'text', component: InputTextComponent },
        { name: 'divider', component: DividerFieldType },
        { name: 'title', component: TitleComponent },
      ],
      wrappers: [
        { name: 'panel', component: PanelWrapperComponent },
        { name: 'addons', component: FormlyWrapperAddons },
      ],
      extensions: [
        { name: 'addons', extension: { onPopulate: addonsExtension } },
      ],
      validators: [
        { name: 'cp', validation: CpValidator },
        { name: 'email', validation: Validators.email },
        { name: 'password', validation: PasswordValidator },
        {
          name: 'passwordConfirmation',
          validation: PasswordConfirmationValidator,
        },
        { name: 'date', validation: DateValidator },
      ],
      validationMessages: [
        { name: 'cp', message: CpValidatorMessage },
        { name: 'email', message: EmailValidatorMessage },
        { name: 'password', message: PasswordValidatorMessage },
        {
          name: 'passwordConfirmation',
          message: PasswordConfirmationValidatorMessage,
        },
        { name: 'date', message: DateValidatorMessage },
      ],
    }),
    FormlyMaterialModule,
    FormlyMatDatepickerModule,
    FormlyMatToggleModule,
    BaseChartDirective,
    ColorPickerModule,
    NgxExtendedPdfViewerModule,
  ],
  exports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    AngularSvgIconModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    MenuListItemComponent,
    GameCardComponent,
    InfoCardComponent,
    SeriousGameCardComponent,
    StandaloneFilterMenuComponent,
    FormlyModule,
    FormlyMaterialModule,
    FormlyMatToggleModule,
    InputFileComponent,
    ObserverChildDirective,
    SgQuestionsManagementComponent,
    CustomTableComponent,
    BaseChartDirective,
    CreateOrEditDialogComponent,
    LoadingDialogComponent,
    AdminButtonComponent,
    SafeHtmlPipe,
    GameAndInstanceStatsComponent,
    NgxExtendedPdfViewerModule,
  ],
  providers: [
    NavService,
    SnackbarService,
    provideCharts(withDefaultRegisterables(DataLabelsPlugin)),
    //   { provide: LOCALE_ID, useValue: 'fr' }, // Old Version - make bug datepicker
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: DateAdapter, useClass: FrenchDateProviderService },
  ],
})
export class SharedModule { }
