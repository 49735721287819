<section class="flex flex-col gap-10">
  <div class="flex flex-row w-full items-start justify-center gap-5">
    <a (click)="goBack()" class="cursor-pointer flex gap-2.5 items-center mt-[6px] cursor-pointer">
      <mat-icon svgIcon="arrow-left-light" class="w-7 h-7"></mat-icon>
    </a>
    <span class="inline-flex items-center">
      <h2>Fyyyre – Mentions légales</h2>
    </span>
  </div>
  <div class="flex flex-col gap-5">
    <h4>Editeur de la Plateforme</h4>
    <ul class="ml-4">
      <li>
        <p>● <b>Société </b> : SARL Fyyyre</p>
      </li>
      <li>
        <p>● <b>Siège social </b> : 1014 Montée Neuve, 01700 Miribel.</p>
      </li>
      <li>
        <p>● <b>SIRET</b> : 98512920400014</p>
      </li>
      <li>
        <p>● <b>Directeur de la publication</b> : Virgile FONTANA</p>
      </li>
      <li>
        <p>● <b>Contact</b> : {{'contact@fyyyre.fr'}}</p>
      </li>
    </ul>
  </div>
  <div class="flex flex-col gap-5">
    <h4>Hébergement de la plateforme</h4>
    <ul class="ml-4">
      <li>
        <p>● <b>Nom de l’hébergeur</b> : Layxo</p>
      </li>
      <li>
        <p>● <b>Adresse de l’hébergeur</b> : 57 Rue des Girondins, 69007 Lyon</p>
      </li>
      <li>
        <p>● <b>Contact de l’hébergeur</b> : {{'contact@layxo.com'}}</p>
      </li>
    </ul>

  </div>
  <div class="flex flex-col gap-5">
    <h4>Conditions d’utilisation</h4>
    <p>Les CGU destinées aux Apprenants utilisant la Plateforme sont disponibles <a
        class="font-medium hover:text-indigo-500 underline" [routerLink]=" ['/legal/cgu-learners']">ici</a>.
    </p>
    <p>Les CGU destinées aux Formateurs utilisant la Plateforme sont disponibles <a
        class="font-medium hover:text-indigo-500 underline" [routerLink]=" ['/legal/cgu-trainers']">ici</a>.
    </p>
  </div>
  <div class="flex flex-col gap-5">
    <h4>Cookies</h4>
    <p>Les informations relatives aux cookies déposés lors de l’utilisation de la Plateforme peuvent être consultées <a
        [routerLink]=" ['/legal/cookies']" class="font-medium hover:text-indigo-500 underline">ici</a>.
    </p>
  </div>
</section>
