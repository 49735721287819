import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class InstanceService {
  constructor(private apiService: ApiService, private http: HttpClient) { }

  createGameInstance(gameId: string, instance: any) {
    return this.apiService.post(`/games/${gameId}/instances`, instance);
  }

  getGameInstances(gameId: string) {
    return this.apiService.get(`/games/${gameId}/instances`);
  }

  getGameInstance(gameId: string, instanceId: string) {
    return this.apiService.get(`/games/${gameId}/instances/${instanceId}`);
  }

  getGameInstanceAsManager(gameId: string, instanceId: string) {
    return this.apiService.get(`/games/${gameId}/instances/${instanceId}/as-manager`);
  }

  updateGameInstance(gameId: string, instance: any) {
    return this.apiService.patch(
      `/games/${gameId}/instances/${instance.id}`,
      instance
    );
  }

  getManagers(gameId: string, instanceId: string) {
    return this.apiService.get(
      `/games/${gameId}/instances/${instanceId}/managers`
    );
  }

  addManagers(gameId: string, instanceId: string, managers: any) {
    return this.apiService.post(
      `/games/${gameId}/instances/${instanceId}/managers`,
      managers
    );
  }

  removeManagers(gameId: string, instanceId: string, managers: any) {
    return this.apiService.deleteWithBody(
      `/games/${gameId}/instances/${instanceId}/managers`,
      managers
    );
  }

  getPlayers(gameId: string, instanceId: string) {
    return this.apiService.get(
      `/games/${gameId}/instances/${instanceId}/participants`
    );
  }

  getAvailablePlayers(gameId: string, instanceId: string, search?: string) {
    return this.apiService.get(
      `/games/${gameId}/instances/${instanceId}/participants/available?${search ? 'search=' + search : ''}`
    );
  }

  addPlayers(gameId: string, instanceId: string, players: any) {
    return this.apiService.post(
      `/games/${gameId}/instances/${instanceId}/participants`,
      { users: players }
    );
  }

  removePlayers(gameId: string, instanceId: string, players: any) {
    return this.apiService.deleteWithBody(
      `/games/${gameId}/instances/${instanceId}/participants`,
      players
    );
  }

  removeInstance(gameId: string, instanceId: string) {
    return this.apiService.delete(`/games/${gameId}/instances/${instanceId}`);
  }

  canLaunchGameInstance(gameId: string, instanceId: string) {
    return this.apiService.get(
      `/games/${gameId}/instances/${instanceId}/can-launch`
    );
  }

  getGameInstanceStory(gameId: string, instanceId: string) {
    return this.apiService.get(
      `/games/${gameId}/instances/${instanceId}/story`
    );
  }

  getRanking(gameId: string, instanceId: string) {
    return this.apiService.get(
      `/games/${gameId}/instances/${instanceId}/participants/ranking`
    );
  }
}
