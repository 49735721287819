import { Location } from '@angular/common';
import { Component } from '@angular/core';


export interface data {
  utilisateur: string;
  utilisateurs90: string;
  utilisateurs120: string;
  utilisateurs160: string;
}

const ELEMENT_DATA: data[] = [
  {
    utilisateur: 'Coût',
    utilisateurs90: 'Pas de coût supplémentaire.',
    utilisateurs120: 'Facturation de 20 000 € supplémentaires pour deux tranches de 10 utilisateurs. ((100000/100) * 10 * 2 = 20 000)',
    utilisateurs160: 'Émission d’un devis complémentaire sur mesure.'
  },
];

@Component({
  selector: 'app-cgu-trainers',
  templateUrl: './cgu-trainers.component.html',
  styleUrl: './cgu-trainers.component.scss'
})
export class CguTrainersComponent {
  displayedColumns: string[] = ['utilisateur', 'utilisateurs90', 'utilisateurs120', 'utilisateurs160'];
  dataSource = ELEMENT_DATA;


  constructor(private _location: Location) { }

  goBack() {
    this._location.back();
  }
}
