import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@app/data/models/user.model';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { BehaviorSubject, Observable, catchError, map } from 'rxjs';
import { ApiService } from '../../data/api/api.service';

import { Game } from '@app/data/models/game.model';
import { Permission } from '@app/data/models/permission.model';
import { Ability, AbilityBuilder } from '@casl/ability';
import { ThemeService } from './theme.service';


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private userSubject: BehaviorSubject<User | null>;
  public user: Observable<User | null>;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private cookieService: SsrCookieService,
    private themeService: ThemeService
  ) {
    this.userSubject = new BehaviorSubject<User | null>(null);
    this.user = this.userSubject.asObservable();

  }

  public get userValue() {
    return this.userSubject.value;
  }

  isSignedIn(): boolean {
    return this.cookieService.check('active_session');
  }

  isAssociatedToHolding(): boolean {
    return this.cookieService.check('current-holding');
  }

  loadAppSession() {
    if (this.cookieService.check('active_session')) {
      this.refreshToken().subscribe();
      this.loadUserCurrentGameFromCookie();
    } else {
      this.userSubject.next(null);
    }
  }

  /**
   * Method allowing a user to create an account using an email and a password.
   * @param first_name
   * @param last_name
   * @param username
   * @param password
   * @returns Observable
   */
  // signUp(
  //   first_name: string,
  //   last_name: string,
  //   username: string,
  //   password: string
  // ): Observable<any> {
  //   this.cookieService.set('active_session', Date.now().toString(), {
  //     path: '/',
  //     expires: 90,
  //   });
  //   return this.apiService
  //     .post('/auth/signup?use-cookies=true', {
  //       first_name: first_name,
  //       last_name: last_name,
  //       email: username,
  //       password: password,
  //     })
  //     .pipe(
  //       map((data: any) => {
  //         // this.userSubject.next(undefined);
  //         this.fetchUserProfileAndPermissions();
  //         this.router.navigate(['/home']);
  //       }),
  //       catchError((error) => {
  //         this.cookieService.delete('active_session', '/');
  //         return error;
  //       })
  //     );
  // }

  /**
   * Method allowing a user to connect by entering his username and password.
   * @param username
   * @param password
   * @returns Observable
   */
  signIn(username: string, password: string): Observable<any> {
    this.cookieService.set('active_session', Date.now().toString(), {
      path: '/',
      expires: 90,
    });
    return this.apiService
      .post('/auth/signin?use-cookies=true', {
        username: username,
        password: password,
      })
      .pipe(
        map((data: any) => {
          // this.userSubject.next(undefined);
          this.fetchUserProfileAndPermissions();
          // this.router.navigate(['/home']);
        }),
        catchError((error) => {
          this.cookieService.delete('active_session', '/');
          return error;
        })
      );
  }

  /**
   * Method allowing a user to disconnect from the application
   */
  signOut() {
    this.cookieService.deleteAll('/');
    this.router.navigate(['/auth/signin']); // Redirigez vers la page de connexion après la déconnexion.
    this.apiService.post('/auth/signout', {}).pipe(
      map((data: any) => { }),
      catchError((error) => {
        console.log(error);
        return error;
      })
    );
  }

  /**
   * @returns true or false depending on the success of the refresh
   */
  refreshToken(): Observable<boolean> {
    return this.apiService.get('/auth/refresh?use-cookies=true').pipe(
      map((data: any) => {
        this.cookieService.delete('active_session', '/');
        this.cookieService.set('active_session', Date.now().toString(), {
          path: '/',
          expires: 90,
        });
        this.fetchUserProfileAndPermissions();
        // if (this.router.url.includes('auth')) {
        //   this.router.navigate(['/home']);
        // }
        return true;
      }),
      catchError((error) => {
        this.signOut();
        return Promise.resolve(false);
      })
    );
  }

  fetchUserProfileAndPermissions() {
    this.apiService.get('/auth/profile').subscribe((user: User) => {
      this.userSubject.next(user);
      this.cookieService.set('active_session', Date.now().toString(), {
        path: '/',
        expires: 90,
      });

      if (user.role) {
        if (user.role.permissions.length > 0) {
          this.updateAbility(user.role.permissions);
        }
        if (user.role.level <= 4) {
          if (
            this.router.url.includes('auth') ||
            /*  this.router.url === '/' || */
            this.router.url === '/home'
          ) {
            this.router.navigate(['/admin/home']);
          }
        } else {
          if (
            this.router.url.includes('auth') ||
            /* this.router.url === '/' || */
            this.router.url === '/admin/home'
          ) {
            this.router.navigate(['/home']);
          }
        }
      } else {
        if (
          this.router.url.includes('auth') ||
          /* this.router.url === '/' || */
          this.router.url === '/admin/home'
        ) {
          this.router.navigate(['/home']);
        }
      }
    });
  }

  private updateAbility(permissions: Permission[]) {
    const { can, rules } = new AbilityBuilder(Ability);
    permissions.forEach((permission: Permission) => {
      can(permission.action, permission.subject, permission.condition);
    });
    // this.ability.update(rules);
  }

  updateUserPassword(objPassword: {
    old_password: string;
    new_password: string;
  }) {
    return this.apiService.patch(`/auth/update-password`, objPassword);
  }

  resetPassword(password: string, resetCode: string) {
    return this.apiService.post(`/auth/reset-password`, {
      new_password: password,
      reset_password_token: resetCode,
    });
  }

  sendResetPassword(userEmail: string): Observable<any> {
    return this.apiService.get(`/auth/reset-password-email/${userEmail}`);
  }

  getUserInfoFromInvitationCode(invitationCode: string) {
    return this.apiService.get(`/auth/invitation/${invitationCode}`);
  }

  acceptInvitation(
    invitationCode: string,
    firstName: string,
    lastName: string,
    password: string
  ) {
    return this.apiService
      .post(`/auth/invitation/${invitationCode}/accept?use-cookies=true`, {
        first_name: firstName,
        last_name: lastName,
        password: password,
      })
      .pipe(
        map((data: any) => {
          this.fetchUserProfileAndPermissions();
          const _router = this.router;
          setTimeout(function () {
            // Delay 500ms before redirecting to let time to the app to update the user
            _router.navigate(['/home']);
          }, 500);
        }),
        catchError((error) => {
          console.log(error);
          this.cookieService.delete('active_session', '/');
          return error;
        })
      );
  }
  /* ------------------------------ USER LOG IN  ------------------------------ */

  updateLoggedUser(id: string, user: User): Observable<User> {
    return this.apiService.patch(`/users/${id}`, user);
  }
  updateLoggedUserAvatar(id: string, avatar: FormData): Observable<User> {
    return this.apiService.postImage(`/users/${id}/avatar`, avatar);
  }
  /* ------------------------------ USER LOG IN  ------------------------------ */
  /* ------------------------------ APP LANGUAGE ------------------------------ */
  setUserAppLanguage(language?: string) {
    const lang = language ?? navigator.language.split('-')[0];
    this.cookieService.set('current-language', lang, {
      path: '/',
      expires: 90,
    });
  }

  updateUserAppLanguage(language?: string) {
    const lang = language ?? navigator.language.split('-')[0];
    this.cookieService.set('current-language', lang, {
      path: '/',
      expires: 90,
    });
    window.location.reload();
  }

  setUserPreferences(id: string, preferences: any): any {
    this.apiService.patch(`/users/${id}`, preferences).subscribe((user) => {
      this.userSubject.next(user);
    });
  }

  getUserAppLanguage(): string {
    return this.cookieService.get('current-language');
  }
  checkUserAppLanguage() {
    return this.cookieService.check('current-language');
  }
  /* ------------------------------ / APP LANGUAGE ------------------------------ */
  /* ------------------------------ Custom methods ------------------------------ */
  loadUserCurrentGameFromCookie() {
    const cookieService = this.cookieService;
    const apiService = this.apiService;
    const userSubject = this.userSubject;
    const themeService = this.themeService
    this.user.subscribe({
      next(user) {
        if (user && cookieService.check('current-game')) {
          const gameId = cookieService.get('current-game');
          if (user?.currentGame?.id !== gameId) {
            const game = apiService.get(`/games/${gameId}`).subscribe({
              next: (game: Game) => {
                user.currentGame = game;
                userSubject.next(user);

                // Load Game Theming
                themeService.loadGameTheme({ primary: game.primary_color, secondary: game.accent_color, tertiary: game.warn_color });

                if (user && cookieService.check('current-game-instance')) {
                  const instanceId = cookieService.get('current-game-instance');
                  if (user?.currentGameInstance?.id !== instanceId) {
                    const game = apiService
                      .get(`/games/${gameId}/instances/${instanceId}`)
                      .subscribe({
                        next: (instance: any) => {
                          user.currentGameInstance = instance;
                          userSubject.next(user);
                        },
                        error: (error) => { },
                      });
                  }
                }
              },
              error: (error) => { },
            });
          }
        }
      },
    });
  }

  setUserCurrentGameCookie(gameId: string) {
    this.cookieService.delete('current-game', '/');
    this.cookieService.set('current-game', gameId, {
      path: '/',
      expires: 90,
    });
  }

  setUserCurrentGameInstanceCookie(instanceId: string) {
    this.cookieService.delete('current-game-instance', '/');
    this.cookieService.set('current-game-instance', instanceId, {
      path: '/',
      expires: 90,
    });
  }

  setUserCurrentGameAndInstanceFromIds(gameId: string, instanceId?: string) {
    const user = this.userValue;
    if (user) {
      const game = this.apiService.get(`/games/${gameId}`).subscribe({
        next: (game: Game) => {
          this.setUserCurrentGameCookie(game.id);
          user.currentGame = game;
          this.userSubject.next(user);
          if (instanceId) {
            this.apiService
              .get(`/games/${gameId}/instances/${instanceId}`)
              .subscribe({
                next: (instance: any) => {
                  this.setUserCurrentGameInstanceCookie(instance.id);
                  user.currentGameInstance = instance;
                  this.userSubject.next(user);
                },
                error: (error) => { },
              });
          }
        },
        error: (error) => { },
      });
    }
  }
  /* ------------------------------ / Custom methods ------------------------------ */
}
