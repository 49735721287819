import { Location } from '@angular/common';
import { Component } from '@angular/core';
@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrl: './terms-and-conditions.component.scss'
})
export class TermsAndConditionsComponent {

  constructor(private _location: Location) { }

  goBack() {
    this._location.back();
  }
}
