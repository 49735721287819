<section class="flex flex-col gap-10">
  <div class="flex flex-row w-full items-start justify-center gap-5">
    <a (click)="goBack()" class="cursor-pointer flex gap-2.5 items-center mt-[6px] cursor-pointer">
      <mat-icon svgIcon="arrow-left-light" class="w-7 h-7"></mat-icon>
    </a>
    <span class="inline-flex items-center">
      <h2>Fyyyre – Informations relatives aux cookies</h2>
    </span>
  </div>
  <div>
    <p>Fyyyre utilise uniquement des cookies nécessaires au bon fonctionnement de l’application.</p>
    <p>Les cookies fonctionnels utilisés sont les suivants :</p>
    <ul class="ml-4">
      <li>
        <p>● <b>active_session</b> : permet de gérer la session de l’utilisateur actif</p>
      </li>
      <li>
        <p>● <b>current-language</b> : permet de gérer le langage de l’application</p>
      </li>
      <li>
        <p>● <b>current-game</b> : permet de stocker l’id du jeu actuel de l’utilisateur</p>
      </li>
      <li>
        <p>● <b>current-language-instance</b> : permet de stocker l’instance de jeu actuelle de l’utilisateur</p>
      </li>
    </ul>
  </div>
</section>
