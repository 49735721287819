import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-cgu-learners',
  templateUrl: './cgu-learners.component.html',
  styleUrl: './cgu-learners.component.scss'
})
export class CguLearnersComponent {

  constructor(private _location: Location) { }

  goBack() {
    this._location.back();
  }

}
