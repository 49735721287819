import { Location } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrl: './cookies.component.scss'
})
export class CookiesComponent {

  constructor(private _location: Location) { }

  goBack(): void {
    this._location.back();
  }

}
