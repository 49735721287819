@if(router.url){
<!-- <a color="accent" (click)="goBackToAdminPanel()" class="text-white w-fit m-4 absolute right-0 top-0" [hidden]="hidden"
  mat-flat-button>
  <mat-icon svgIcon="new-tab-light" class="mx-2"></mat-icon>
  <span class="mr-2">Editer l'aventure</span>
</a> -->
<!-- <span class="mr-2">{{this.router!.url.includes('levels') ? 'Retourner à l\'étape' :
      this.router!.url.includes('chapters') ? 'Retourner aux chapitres' : 'Retourner au jeu'}}</span> -->

<a color="accent" (click)="goBackToAdminPanel()" class="text-white" [hidden]="hidden" mat-flat-button>
  <mat-icon svgIcon="new-tab-light" class="m-0 md:mx-2 min-h-4"></mat-icon>
  <span class="hidden md:block">Editer l'aventure</span>
</a>
}
